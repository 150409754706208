<template>
    <div>
      <div class="tool">
        <el-button type="primary" class="el-icon-plus" @click="MoreChangePicture"> 批量换画</el-button>
        <el-input placeholder="点位名称或编号" style="width:200px;margin-left:10px" v-model="searchForm.searchInfo">
          <el-button slot="append" icon="el-icon-search" @click="Search"></el-button>
        </el-input>
      </div>

        <el-table :data='MediaDataList' stripe :highlight-current-row='true' ref="mediaTable" max-height='calc(100vh - 165px)' height='calc(100vh - 165px)'>
            <el-table-column align="center" type="selection" width="50"></el-table-column>
            <el-table-column align="center" prop="media_no" label="媒体编号"></el-table-column>
            <el-table-column align="center" prop="media_name" label="媒体名称"></el-table-column>
            <el-table-column align="center" prop="media_community_addr" label="媒体地址"></el-table-column>
            <el-table-column align="center" prop="media_addr" label="点位地址"></el-table-column>
            <el-table-column align="center" prop="order_name" label="订单名称"></el-table-column>
            <el-table-column align="center" prop="order_begin_time" label="起始日期">
                <template slot-scope="slot">
                    {{FormateData(slot.row.order_begin_time,0)}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="order_end_time" label="结束日期">
                <template slot-scope="slot">
                    {{FormateData(slot.row.order_end_time,0)}}
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <div class="link_btn">
                        <el-link type="primary" @click="ChangeMedia(scope.row)">换刊</el-link>
                        <el-link type="danger" @click="ChangePicture(scope.row)">换画</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="fy" layout="prev, pager, next" @current-change="current_change" :total="searchForm.total" background :page-size="searchForm.pageSize"></el-pagination>

        <!--换刊-->
        <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogVisible" width='70%' :append-to-body='true'>
         <el-form :model="changeForm" ref="changeForm" label-width="100px" size='medium'>
           <el-form-item label="当前点位">
                <el-input v-model="changeForm.media_name" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="更换后点位">
                <el-input v-model="newMediaName">
                   <el-button slot="append" icon="el-icon-refresh-right" @click="SearchChangeMedia"></el-button>
                </el-input>
            </el-form-item>
            <el-form-item label="换刊人员">
              <el-select v-model="changeForm.sp_id" style="width:100%;">
                <el-option v-for="item in SalePerDataList" :key="item.user_id" :value="item.user_id" :label="item.user_name" ></el-option>
                <el-option  :key="0" :value="0" label=" " ></el-option>
              </el-select>
          </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="changeForm.remark"></el-input>
            </el-form-item>
         </el-form>
          <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="Save">确 定</el-button>
          </span>
      </el-dialog>

      <el-dialog :title="mediaTitle" :close-on-click-modal="false" :visible.sync="mediaDialogVisible" width='50%' :append-to-body='true'>
        <div class="content">
          <el-input placeholder="请输入点位名称或编号" v-model="SecForm.mediaInfo" style="width:200px">
              <el-button slot="append" icon="el-icon-search" @click="SearchMedia"></el-button>
          </el-input>
          <el-table :data='DataList' stripe :highlight-current-row='true' ref="tb" @select="Checked">
            <el-table-column align="center" type="selection" width="50"></el-table-column>
            <el-table-column align="center" prop="media_no" label="编号"></el-table-column>
            <el-table-column align="center" prop="media_name" label="名称"></el-table-column>
            <el-table-column align="center" prop="media_addr" label="位置"></el-table-column>
            <el-table-column align="center" prop="media_show_type" label="点位类型">
              <template slot-scope="slot">
                {{slot.row.media_show_type===0?'灯箱广告':(slot.row.media_show_type===1?'道闸广告':(slot.row.media_show_type===2?'电梯框架':(slot.row.media_show_type===3?'广告看板':(
                  slot.row.media_show_type===4?'屏蔽门':(slot.row.media_show_type===5?'候车亭广告':(slot.row.media_show_type===6?'车身广告':'其他'))
                ))))}}
              </template>
            </el-table-column>
          </el-table>
           <el-pagination class="fy" layout="prev, pager, next" @current-change="currentChange" :total="SecForm.total" background :page-size="SecForm.pageSize"></el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="mediaDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="Ok">确 定</el-button>
        </span>
      </el-dialog>

        <!--换画-->
      <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="picDialogVisible" width='50%' :append-to-body='true'>
         <el-form :model="changeForm" ref="changeForm" label-width="120px" size='medium'>
            <el-row :gutter="15">
              <el-form-item label="当前媒体："  v-if="changeForm.optstate===1">
                <el-input v-model="changeForm.media_name" readonly="readonly"></el-input>
              </el-form-item>
              <el-form-item label="换画图片：">
                <UploadPic v-if="picDialogVisible"  :photoList="changeForm.picList"  ref='childRules'/>
              </el-form-item>
              <el-form-item label="换画人员">
                <el-select v-model="changeForm.sp_id" style="width:100%;">
                  <el-option v-for="item in SalePerDataList" :key="item.user_id" :value="item.user_id" :label="item.user_name" ></el-option>
                  <el-option  :key="0" :value="0" label=" " ></el-option>
                </el-select>
            </el-form-item>
              <el-form-item label="备注：">
                <el-input v-model="changeForm.remark"></el-input>
              </el-form-item>
            </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
                <el-button @click="picDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="Save">确 定</el-button>
          </span>
      </el-dialog>
    </div>
</template>

<script>
import util from '../../util/util'
import UploadPic from '../../components/UploadPicture.vue'

export default {
  data: function () {
    return {
      searchForm: {
        searchInfo: '',
        pageNo: 1,
        pageSize: 20,
        total: 0
      },

      SecForm: {
        pageNo: 1,
        pageSize: 20,
        total: 0,
        order_id: 0,
        mediaInfo: ''
      },

      MediaDataList: [],
      DataList: [],
      SalePerDataList:[],

      title: '',
      dialogVisible: false,
      changeForm: {
        optstate: 0,
        mediaIds: [], // 媒体id
        newMediaIds: [], // 更换后的媒体id
        picList: [],
        remark: '',
        sp_id:0//操作员
      },
      CheckedMediaData: [],
      newMediaName: '',

      mediaTitle: '',
      mediaDialogVisible: false,

      picDialogVisible: false
    }
  },
  computed: {
    FormateData () {
      return function (time, type) {
        return util.FormateDate(time, type)
      }
    }
  },
  components: {
    UploadPic
  },
  created () {
    this.GetDataList()
    if (this.SalePerDataList.length === 0) {
        var User=util.JesToken()
        util.Get('user/getuserdatalist?userType=3&id='+User.user_sp_id).then(res=>{
            if (res.rpStatus === 10000) {
                this.SalePerDataList = res.list
            } else {
                this.$message.error(res.rpMsg)
            }
        })
    }
  },
  methods: {
    GetDataList () {
      util.Get('mediachange/GetOrderDataList', this.searchForm).then(res => {
        if (res.rpStatus === 10000) {
          this.MediaDataList = res.list
          this.searchForm.total = res.total
        }
      })
    },
    Search(){
      this.searchForm.pageNo=1
      this.GetDataList()
    },
    GetMediaDataList () {
      this.SecForm.check_type = 2
      this.SecForm.mediaInfo = this.SecForm.searchInfo
      this.SecForm.media_show_type = -1
      util.Post('applyorder/GetMediaPlan', this.SecForm).then(res => {
        if (res.rpStatus === 10000) {
          this.DataList = res.list
          this.SecForm.total = res.total
        }
      })
    },
    SearchMedia () {
      this.GetMediaDataList()
    },
    current_change (page) {
      this.searchForm.pageNo = page
      this.GetDataList()
    },

    // 换刊
    currentChange (page) {
      this.SecForm.pageNo = page
      this.GetMediaDataList()
    },
    ChangeMedia (row) {
      this.changeForm.optstate = 0
      this.dialogVisible = true
      this.title = '换刊'
      this.changeForm.mediaIds = []
      this.changeForm.order_id = row.order_id
      this.changeForm.mediaIds.push(row.media_id)
      this.changeForm.media_name = row.media_name
      this.newMediaName = ''
      this.remark = ''
      this.CheckedMediaData = []
      this.SecForm.order_id = row.order_id
    },
    SearchChangeMedia () {
      this.mediaDialogVisible = true
      this.mediaTitle = '媒体换刊'
      this.SecForm.mediaInfo = ''
      this.GetMediaDataList()
    },
    Checked (selection, row) {
      var arr = selection.filter(a => a.media_id === row.media_id)
      if (arr.length > 0) {
        var list = this.CheckedMediaData.filter(a => a.media_id === row.media_id)
        if (list.length === 0) {
          this.CheckedMediaData.push({
            media_id: row.media_id,
            media_name: row.media_name,
            media_addr:row.media_addr
          })
        }
      } else {
        var index = this.CheckedMediaData.findIndex(a => a.media_id === row.media_id)
        this.$delete(this.CheckedMediaData, index)
      }
    },
    Ok () {
      if (this.CheckedMediaData.length === 0) {
        this.$message.error('请选择换刊的媒体')
        return
      }
      this.mediaDialogVisible = false
      var t = ''
      this.changeForm.newMediaIds = []
      this.newMediaName=t
      this.CheckedMediaData.forEach(a => {
        t += a.media_name +a.media_addr +','
        this.changeForm.newMediaIds.push(a.media_id)
      })
      this.newMediaName = t
    },

    // 换画
    ChangePicture (row) {
      this.changeForm.optstate = 1
      this.picDialogVisible = true
      this.changeForm.mediaIds = []
      this.changeForm.newMediaIds = []
      this.changeForm.mediaIds.push(row.media_id)
      this.changeForm.media_name = row.media_name
      this.title = '换画'
    },
    MoreChangePicture () {
      var list = this.$refs.mediaTable.selection
      this.changeForm.mediaIds = []
      this.changeForm.newMediaIds = []
      if (list.length > 0) {
        list.forEach(a => {
          this.changeForm.mediaIds.push(a.media_id)
        })
        this.changeForm.optstate = 1
        this.picDialogVisible = true
        this.title = '换画'
      }
    },

    Save () {
      if (this.changeForm.optstate === 1) { this.changeForm.picList = this.$refs.childRules.ReturnPicData() }
      util.Post('mediachange/change', this.changeForm).then(res => {
        if (res.rpStatus === 10000) {
          this.picDialogVisible = false
          this.dialogVisible = false
          this.GetDataList()
        }
      })
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.tool{
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
}
.el-table{
    margin-top: 10px;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>>
